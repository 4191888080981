import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ConfigAPI from '@/config/api'
import Session from '@/handler/session'

Vue.use(VueAxios, axios)

const instance = axios.create();

const servicesAPI = {
  /*************/
  /*   SLIDER  */
  /*************/

  obtenerSliders() {
    var url = ConfigAPI.baseURL + "contents-sliders" + Session.getToken();
    return instance.get(url);
  },
  mostrarSliders(id) {
    var url = ConfigAPI.baseURL + "contents-sliders/" + id + Session.getToken();
    return instance.get(url);
  },
  agregarSliders(data, image1) {
    var headers = {
      'Content-Type': 'multipart/form-data'
    }

    var formData = new FormData();
    data = JSON.parse(data)
    for ( var key in  data) {
      if(key!="image") {
        formData.append(key, data[key]);
      }

      if(key=="image") {
        var i = 0
        image1.forEach((value, key) => {
          formData.append('image_' + i, value);
          i++
        })
        formData.append('image', i);
      }
    }

    var params = formData
    
    var url = ConfigAPI.baseURL + "contents-sliders" + Session.getToken();
    return instance.post(url,params,{headers});
  },
  editarSliders(data, image1) {
    var headers = {
      'Content-Type': 'multipart/form-data'
    }

    var formData = new FormData();
    data = JSON.parse(data)
    for ( var key in  data) {
      if(key!="image") {
        formData.append(key, data[key]);
      }

      if(key=="image") {
        var i = 0
        image1.forEach((value, key) => {
          formData.append('image_' + i, value);
          i++
        })
        formData.append('image', i);
      }
    }

    var params = formData    

    var url = ConfigAPI.baseURL + "contents-sliders/"+ data.id + Session.getToken();
    return instance.post(url,params,{headers});
  },  
  eliminarSliders(id) {
    var url = ConfigAPI.baseURL + "contents-sliders/"+ id + Session.getToken();
    return instance.delete(url);
  }, 

  /************/
  /*   POPUS  */
  /************/

  obtenerPopups() {
    var url = ConfigAPI.baseURL + "contents-popups" + Session.getToken();
    return instance.get(url);
  },
  mostrarPopups(id) {
    var url = ConfigAPI.baseURL + "contents-popups/" + id + Session.getToken();
    return instance.get(url);
  },
  agregarPopups(data, image1) {
    var headers = {
      'Content-Type': 'multipart/form-data'
    }

    var formData = new FormData();
    data = JSON.parse(data)
    for ( var key in  data) {
      if(key!="image") {
        formData.append(key, data[key]);
      }

      if(key=="image") {
        var i = 0
        image1.forEach((value, key) => {
          formData.append('image_' + i, value);
          i++
        })
        formData.append('image', i);
      }
    }

    var params = formData
    
    var url = ConfigAPI.baseURL + "contents-popups" + Session.getToken();
    return instance.post(url,params,{headers});
  },
  editarPopups(data, image1) {
    var headers = {
      'Content-Type': 'multipart/form-data'
    }

    var formData = new FormData();
    data = JSON.parse(data)
    for ( var key in  data) {
      if(key!="image") {
        formData.append(key, data[key]);
      }

      if(key=="image") {
        var i = 0
        image1.forEach((value, key) => {
          formData.append('image_' + i, value);
          i++
        })
        formData.append('image', i);
      }
    }

    var params = formData    

    var url = ConfigAPI.baseURL + "contents-popups/"+ data.id + Session.getToken();
    return instance.post(url,params,{headers});
  },
  eliminarPopups(id) {
    var url = ConfigAPI.baseURL + "contents-popups/"+ id + Session.getToken();
    return instance.delete(url);
  },
  
  /****************/
  /*   DESCARGAS  */
  /****************/

  obtenerDownloads() {
    var url = ConfigAPI.baseURL + "contents-downloads" + Session.getToken();
    return instance.get(url);
  },
  mostrarDownloads(id) {
    var url = ConfigAPI.baseURL + "contents-downloads/" + id + Session.getToken();
    return instance.get(url);
  },
  agregarDownloads(data, formData) {        
    var headers = {
      'Content-Type': 'multipart/form-data'
    }

    data = JSON.parse(data)
    for (var key in  data) {
      if(key!="file") {
        formData.append(key, data[key]);
      }
    }
  
    var params = formData
    var url = ConfigAPI.baseURL + "contents-downloads" + Session.getToken();
    return instance.post(url,params,{headers});
  },
  editarDownloads(data, formData) {    
    var headers = {
      'Content-Type': 'multipart/form-data'
    }

    data = JSON.parse(data)
    for (var key in  data) {
      if(key!="file") {
        formData.append(key, data[key]);
      }
    }
  
    var params = formData    
    var url = ConfigAPI.baseURL + "contents-downloads/"+ data.id + Session.getToken();
    return instance.post(url,params,{headers});
  },
  eliminarDownloads(id) {
    var url = ConfigAPI.baseURL + "contents-downloads/"+ id + Session.getToken();
    return instance.delete(url);
  },

  /***********************/
  /*   METODOS DE PAGOS  */
  /***********************/

  obtenerPaymentMethods() {
    var url = ConfigAPI.baseURL + "contents-payment-methods" + Session.getToken();
    return instance.get(url);
  },
  mostrarPaymentMethods(id) {
    var url = ConfigAPI.baseURL + "contents-payment-methods/" + id + Session.getToken();
    return instance.get(url);
  },
  agregarPaymentMethods(data) {    
    var params = data
    var url = ConfigAPI.baseURL + "contents-payment-methods" + Session.getToken();
    return instance.post(url,params);
  },
  editarPaymentMethods(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "contents-payment-methods/"+ data.id + Session.getToken();
    return instance.post(url,params);
  },
  eliminarPaymentMethods(id) {
    var url = ConfigAPI.baseURL + "contents-payment-methods/"+ id + Session.getToken();
    return instance.delete(url);
  },  

  /*******************/
  /*    PAGINAS      */
  /*******************/

  obtenerPagina() {
    var url = ConfigAPI.baseURL + "contents-page" + Session.getToken();
    return instance.get(url);
  },
  mostrarPagina(id) {
    var url = ConfigAPI.baseURL + "contents-page/" + id + Session.getToken();
    return instance.get(url);
  },
  agregarPagina(data) {
    var params = data
    
    var url = ConfigAPI.baseURL + "contents-page" + Session.getToken();
    return instance.post(url,params);
  },
  duplicarPagina(data) {
    var params = data
    
    var url = ConfigAPI.baseURL + "contents-page/duplicate/"+ data.id + Session.getToken();
    return instance.post(url,params);
  },
  editarPagina(data) {
    var params = data    

    var url = ConfigAPI.baseURL + "contents-page/"+ data.id + Session.getToken();
    return instance.post(url,params);
  },  
  eliminarPagina(id) {
    var url = ConfigAPI.baseURL + "contents-page/"+ id + Session.getToken();
    return instance.delete(url);
  },   
  filtrarPagina(data) {
    var params = data

    var url = ConfigAPI.baseURL + "contents-page/filter/query" + Session.getToken();
    return instance.post(url,params);
  },

  agregarPaginaDetail(data) {
    var params = data
    
    var url = ConfigAPI.baseURL + "contents-page/detail/add" + Session.getToken();
    return instance.post(url,params);
  },

  /*******************/
  /*    BLOQUES      */
  /*******************/

  obtenerPaginaBloque() {
    var url = ConfigAPI.baseURL + "contents-page-block" + Session.getToken();
    return instance.get(url);
  },
  mostrarPaginaBloque(id) {
    var url = ConfigAPI.baseURL + "contents-page-block/" + id + Session.getToken();
    return instance.get(url);
  },
  agregarPaginaBloque(data) {
    var params = data
    
    var url = ConfigAPI.baseURL + "contents-page-block" + Session.getToken();
    return instance.post(url,params);
  },
  editarPaginaBloque(data) {
    var params = data    

    var url = ConfigAPI.baseURL + "contents-page-block/"+ data.id + Session.getToken();
    return instance.post(url,params);
  },  
  eliminarPaginaBloque(id) {
    var url = ConfigAPI.baseURL + "contents-page-block/"+ id + Session.getToken();
    return instance.delete(url);
  },   
  editarPaginaBloqueSource(data) {
    var params = data    

    var url = ConfigAPI.baseURL + "contents-page-block/"+ data.id +"/save/source" + Session.getToken();
    return instance.post(url,params);
  },  
  mostrarVariableUsedBloquesSource(data) {
    var params = data    

    var url = ConfigAPI.baseURL + "contents-page-block/"+ data.id +"/get/variables-used" + Session.getToken();
    return instance.post(url, params);
  },
  mostrarVariableAvailablesBloquesSource(data) {
    var params = data    

    var url = ConfigAPI.baseURL + "contents-page-block/"+ data.id +"/get/variables-availables" + Session.getToken();
    return instance.post(url, params);
  },  
  mostrarVariableAvailablesSettingsBloquesSource(data) {
    var params = data    

    var url = ConfigAPI.baseURL + "contents-page-block/"+ data.id +"/get/variables-availables-settings" + Session.getToken();
    return instance.post(url, params);
  },    

  editarPaginaBloqueContentSimple(id, data, arrImages, arrImagesConfig) {
    var headers = {
      'Content-Type': 'multipart/form-data'
    }

    var formData = new FormData();        
    data = JSON.parse(data)    
    for (var key in  data.control) {      
      if(data.images.length) {
        arrImages.forEach((element, index) => {                     
          if(key!=index && !data.images.includes(key)) {
            if(formData.get(key)) {                                   
              formData.set(key, data.control[key]);  
            } else {
              formData.append(key, data.control[key]);  
            }
          }
          
          if(key==index) {                    
            var i = 0
            data.control[index].forEach((value, key) => {            
              if(formData.get(index + '_' + i)) {
                formData.set(index + '_' + i, value.path);
              } else {
                formData.append(index + '_' + i, value.path);
              }

              i++
            })
                        
            if(formData.get(index)) {
              formData.set(index, i);                  
            } else {
              formData.append(index, i);                
            }            
          } 
          
        });    
      } else {
        if(formData.get(key)) {          
          formData.set(key, data.control[key])
        } else {               
          formData.append(key, data.control[key])
        }          
      }              
    }
    
    var arrDataConfig = {}
    for (var key1 in  data.config) {      
      if(data.imagesConfig.length) {
        arrImagesConfig.forEach((element, index1) => {                
          if(key1!=index1) {                                                   
            arrDataConfig[key1] = data.config[key1]
          }
          
          if(key1==index1) {                    
            var i = 0
            data.config[index1].forEach((value1, key) => {                          
              arrDataConfig[index1 + '_' + i] = value1.path
              i++
            })            
            arrDataConfig[index1] = i
          }           
          
        });    
      } else {
        arrDataConfig[key1] = data.config[key1]
      }    
    }    
    formData.append('config', JSON.stringify(arrDataConfig));         
    formData.append('images', JSON.stringify(data.images));         
    formData.append('imagesConfig', JSON.stringify(data.imagesConfig));         
   
    var params = formData
    
    var url = ConfigAPI.baseURL + "contents-page-block/"+ id + '/content/simple' + Session.getToken();
    return instance.post(url,params,{headers});   
  },

  editarPaginaBloqueContentMultiple(id, data, arrImagesMultiple, arrImagesConfig) {
    var headers = {
      'Content-Type': 'multipart/form-data'
    }              
    
    var formData = new FormData();     
    
    data = JSON.parse(data)        
    data.control.forEach((item, item_index) => {
      var jsonVar = {};        
     
      for ( var key in  item) {      
        if(data.images.length) {          
          arrImagesMultiple[item_index].forEach((element, index) => {                                  
            if(key!=index) {                                                 
              jsonVar[key] = item[key];  
            }
            
            if(key==index) {                    
              var i = 0            
              item[index].forEach((value, key) => {                          
                var indexTemp = index + '_' + i
                jsonVar[indexTemp] = value.path;                
                i++
              })
              jsonVar[index] = i;                          
            } 
            
          });          
        } else {
          jsonVar[key] = item[key];  
        }
      }      

      formData.append(item_index, JSON.stringify(jsonVar));                    
    });


    var arrDataConfig = {}
    for (var key1 in  data.config) {      
      if(data.imagesConfig.length) {
        arrImagesConfig.forEach((element, index1) => {                
          if(key1!=index1) {                                                   
            arrDataConfig[key1] = data.config[key1]
          }
          
          if(key1==index1) {                    
            var i = 0
            data.config[index1].forEach((value1, key) => {                          
              arrDataConfig[index1 + '_' + i] = value1.path
              i++
            })            
            arrDataConfig[index1] = i
          }           
          
        });    
      } else {
        arrDataConfig[key1] = data.config[key1]
      }    
    }    
    formData.append('config', JSON.stringify(arrDataConfig));           
    formData.append('images', JSON.stringify(data.images));   
    formData.append('imagesConfig', JSON.stringify(data.imagesConfig));  
        
    var params = formData
    
    var url = ConfigAPI.baseURL + "contents-page-block/"+ id + '/content/multiple' + Session.getToken();
    return instance.post(url,params,{headers});   
  },

  /*************************/
  /*    CONFIGURACION      */
  /*************************/

  obtenerSettings() {
    var url = ConfigAPI.baseURL + "contents-settings" + Session.getToken();
    return instance.get(url);
  },
  editarSettings(id, data) {
    var params = data    

    var url = ConfigAPI.baseURL + "contents-settings/"+ id + Session.getToken();
    return instance.post(url,params);
  },  
  editarSettingsSEO(id, data, image1) {
    var headers = {
      'Content-Type': 'multipart/form-data'
    }

    var formData = new FormData();    
    
    var data = JSON.parse(data)    
    for (var key1 in  data) {    
      if(key1!="values") {
        formData.append(key1, data[key1]);
      }
    }

    var dataImage = data.values
    for (var key2 in  dataImage) {      
      if(key2=="image_main") {
        var i = 0
        image1.forEach((value, key) => {
          formData.append('image_main_' + i, value);
          i++
        })
        formData.append('image_main', i);
      }
    }

    var params = formData
    
    var url = ConfigAPI.baseURL + "contents-settings-seo/"+ id + Session.getToken();
    return instance.post(url,params,{headers});
  },   
  editarSettingsImages(id, data, image1, image2, image3, image4) {
    var headers = {
      'Content-Type': 'multipart/form-data'
    }

    var formData = new FormData();    
    
    var data = JSON.parse(data)    
    for (var key1 in  data) {    
      if(key1!="values") {
        formData.append(key1, data[key1]);
      }
    }

    var dataImage = data.values
    for (var key2 in  dataImage) {     
      if(key2=="logo_primary") { 
        var i = 0
        image1.forEach((value, key) => {
          formData.append('logo_primary_' + i, value);
          i++
        })
        formData.append('logo_primary', i);        
      }

      if(key2=="logo_secondary") {
        var i = 0
        image2.forEach((value, key) => {
          formData.append('logo_secondary_' + i, value);
          i++
        })
        formData.append('logo_secondary', i);       
      }

      if(key2=="logo_footer") {
        var i = 0
        image3.forEach((value, key) => {
          formData.append('logo_footer_' + i, value);
          i++
        })
        formData.append('logo_footer', i);       
      }

      if(key2=="favicon") {
        var i = 0
        image4.forEach((value, key) => {
          formData.append('favicon_' + i, value);
          i++
        })
        formData.append('favicon', i);       
      }      
    }

    var params = formData
    
    var url = ConfigAPI.baseURL + "contents-settings-images/"+ id + Session.getToken();
    return instance.post(url,params,{headers});
  },   
  
  /**********************/
  /*     DESCUENTOS     */
  /**********************/

  obtenerDescuentosTienda() {
    var url = ConfigAPI.baseURL + "contents-store-discounts" + Session.getToken();
    return instance.get(url);
  },
  mostrarDescuentosTienda(id) {
    var url = ConfigAPI.baseURL + "contents-store-discounts/" + id + Session.getToken();
    return instance.get(url);
  },
  agregarDescuentosTienda(data) {    
    var params = data
    var url = ConfigAPI.baseURL + "contents-store-discounts" + Session.getToken();
    return instance.post(url,params);
  },
  editarDescuentosTienda(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "contents-store-discounts/"+ data.id + Session.getToken();
    return instance.post(url,params);
  },
  eliminarDescuentosTienda(id) {
    var url = ConfigAPI.baseURL + "contents-store-discounts/"+ id + Session.getToken();
    return instance.delete(url);
  },

  /********************************/
  /*       ENTREGAS - LOCAL       */
  /********************************/

  obtenerEntregasLocal() {
    var url = ConfigAPI.baseURL + "contents-store-deliveries-local" + Session.getToken();
    return instance.get(url);
  },
  mostrarEntregasLocal(id) {
    var url = ConfigAPI.baseURL + "contents-store-deliveries-local/" + id + Session.getToken();
    return instance.get(url);
  },
  agregarEntregasLocal(data) {    
    var params = data
    var url = ConfigAPI.baseURL + "contents-store-deliveries-local" + Session.getToken();
    return instance.post(url,params);
  },
  editarEntregasLocal(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "contents-store-deliveries-local/"+ data.id + Session.getToken();
    return instance.post(url,params);
  },
  eliminarEntregasLocal(id) {
    var url = ConfigAPI.baseURL + "contents-store-deliveries-local/"+ id + Session.getToken();
    return instance.delete(url);
  },

  /***************************************/
  /*       ENTREGAS - TARIFF SIMPLE      */
  /***************************************/

  obtenerEntregasTariffSimple() {
    var url = ConfigAPI.baseURL + "contents-store-deliveries-tariff" + Session.getToken();
    return instance.get(url);
  },
  mostrarEntregasTariffSimple(id) {
    var url = ConfigAPI.baseURL + "contents-store-deliveries-tariff/" + id + Session.getToken();
    return instance.get(url);
  },
  agregarEntregasTariffSimple(data) {    
    var params = data
    var url = ConfigAPI.baseURL + "contents-store-deliveries-tariff" + Session.getToken();
    return instance.post(url,params);
  },
  editarEntregasTariffSimple(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "contents-store-deliveries-tariff/"+ data.id + Session.getToken();
    return instance.post(url,params);
  },
  eliminarEntregasTariffSimple(id) {
    var url = ConfigAPI.baseURL + "contents-store-deliveries-tariff/"+ id + Session.getToken();
    return instance.delete(url);
  },   

    /***************************************/
  /*       ENTREGAS - TARIFF CONDITIONAL      */
  /***************************************/

  obtenerEntregasTariffConditional() {
    var url = ConfigAPI.baseURL + "contents-store-deliveries-tariff-conditional" + Session.getToken();
    return instance.get(url);
  },
  mostrarEntregasTariffConditional(id) {
    var url = ConfigAPI.baseURL + "contents-store-deliveries-tariff-conditional/" + id + Session.getToken();
    return instance.get(url);
  },
  agregarEntregasTariffConditional(data) {    
    var params = data
    var url = ConfigAPI.baseURL + "contents-store-deliveries-tariff-conditional" + Session.getToken();
    return instance.post(url,params);
  },
  editarEntregasTariffConditional(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "contents-store-deliveries-tariff-conditional/"+ data.id + Session.getToken();
    return instance.post(url,params);
  },
  eliminarEntregasTariffConditional(id) {
    var url = ConfigAPI.baseURL + "contents-store-deliveries-tariff-conditional/"+ id + Session.getToken();
    return instance.delete(url);
  },   

  /***************************************/
  /*       MULTIPLE - CONFIGURACION      */
  /***************************************/

  obtenerForms() {
    var url = ConfigAPI.baseURL + "forms" + Session.getToken();
    return instance.get(url);
  },  

  /***************************************/
  /*            PORTABILITY              */
  /***************************************/

  portabilityLoadTemplate(data, formData) {    
    var headers = {
      'Content-Type': 'multipart/form-data'
    }

    data = JSON.parse(data)
    for (var key in  data) {
      if(key!="file") {
        formData.append(key, data[key]);
      }
    }

    var params = formData
    var url = ConfigAPI.baseURL + "contents-portability-load" + Session.getToken();
    return instance.post(url,params,{headers});
  },  
  portabilityDownloadTemplate(data) {    
    var params = data
    var url = ConfigAPI.baseURL + "contents-portability-download" + Session.getToken();
    return instance.post(url,params);
  },    
  portabilityBlankTemplate(data) {
    var params = data
    var url = ConfigAPI.baseURL + "contents-portability-blank" + Session.getToken();
    return instance.post(url,params);    
  },
  portabilitySelectTemplate(data) {        
    var params = data
    var url = ConfigAPI.baseURL + "contents-portability-select-template" + Session.getToken();
    return instance.post(url,params);
  }, 

  /***************************************/
  /*              TEMPLATE               */
  /***************************************/  

  obtenerTemplates() {
    var url = ConfigAPI.baseURL + "contents-templates" + Session.getToken();
    return instance.get(url);
  },  

  /**************************************/
  /*              BLOQUES               */
  /**************************************/  

  obtenerBloques() {
    var url = ConfigAPI.baseURL + "contents-block" + Session.getToken();
    return instance.get(url);
  },
  agregarBloques(data) {    
    var params = data
    var url = ConfigAPI.baseURL + "contents-block" + Session.getToken();
    return instance.post(url,params);
  },
  editarBloques(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "contents-block/"+ data.id + Session.getToken();
    return instance.post(url,params);
  },      
  eliminarBloques(id) {
    var url = ConfigAPI.baseURL + "contents-block/"+ id + Session.getToken();
    return instance.delete(url);
  }, 
  
  /***********************************/
  /*         GENERIC DATA            */
  /***********************************/  
  obtenerGenericoProvincias(data) {    
    var params = data
    var url = ConfigAPI.baseURL + "public/generic-data/provinces" + Session.getToken();
    return instance.post(url,params);
  },    
}

export default servicesAPI;
