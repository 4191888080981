var storage = {
  setValue (name, value) {        
    sessionStorage.setItem(window.location.origin + "_" + name, value)    
  },
  getValue (name) {        
    return sessionStorage.getItem(window.location.origin + "_" + name)    
  },  
  removeValue (name) {        
    sessionStorage.removeItem(window.location.origin + "_" + name)    
  },
}

export default storage